import axios from "axios";
import config from "@/config";
import {
	v4 as uuid
} from 'uuid'
import {
	getFormatDate,
	tansSortParams,
	tansParams,
	objectOrder,
	deleteParameter
} from "@/utils/topcrm";
import {
	getSign
} from "@/utils/sign";
import {
	Message
} from 'element-ui'
import store from "@/store";
const baseUrl = process.env.VUE_APP_ENV === "development" ? config.baseUrl.dev : config.baseUrl.pro
let timesta = null;
// 定义一个类
class HttpRequest {
	//把类做一个初始化
	constructor(baseUrl) {
		this.baseUrl = baseUrl
	}
	// 配置一下请求头
	getInsideConfig() {

		const config = {
			baseURL: this.baseUrl,
			timeout: 300000,
			headers: {
				appVersion: "1.0.0",
				deviceId: navigator.userAgent,
				deviceName: navigator.userAgent,
				deviceType: 3,
			}
		}
		return config
	}
	//添加拦截器
	interceptors(instance) {
		// 添加请求拦截器
		instance.interceptors.request.use(config => {
			const token = localStorage.getItem("TOKEN")
			const sign = localStorage.getItem("SINGNKEY")
			const timestamp = getFormatDate(),
				nonce = uuid(),
				signKey = sign || "credit@20240516*API"
			let body = "{}"

			// get请求映射params参数
			if (config.method === 'get' && config.params) {
				body = JSON.stringify(tansSortParams(config.params));
				let url = config.url + '?' + tansParams(config.params);
				url = url.slice(0, -1);
				config.params = {};
				config.url = url;
			} else if (config.data) {
				// deleteParameter(config.data)
				// body = objectOrder(config.data)
				body = typeof config.data === 'object' ? JSON.stringify(config.data) : config.data;
			} else if (config.params) {
				body = typeof config.params === 'object' ? JSON.stringify(config.params) : config.params;
			}
			Object.assign(config.headers, {
				Authorization: token ? `Bearer ${token}` : "",
				timestamp,
				nonce,
				sign: getSign(timestamp, nonce, signKey, token, body, config.url)
			})
			return config;
		}, error => {
			return Promise.reject(error)
		});
		// 添加响应拦截器
		instance.interceptors.response.use(response => {
			const code = response.data.code || 200;
			if (code != 200) {
				if (code === 401) {
					clearTimeout(timesta)
					timesta = setTimeout(() => {
						store.dispatch('userInfoFunction', {
							user: '0'
						})
						store.dispatch('centerDialogVisibleFunction', true)
						// Message .error('登录状态已过期，请重新登录');
					}, 700)
				} else if (response.config.titleShow != 1)
					Message.error(response.data.msg)
				return Promise.reject(response.data.msg)
			}
			return response.data
		}, error => {
			return Promise.reject(error)
		})
	}
	request(options) {
		options = {
			...(this.getInsideConfig()),
			...options
		}
		const instance = axios.create()
		// 使用拦截器
		this.interceptors(instance)
		return instance(options)
	}
}
//导出类
export default new HttpRequest(baseUrl)