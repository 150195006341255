<template>
    <div class="protocolPolicyClass">
        <el-dialog :visible.sync="show" align-center @close="$emit('update:value', false)">
            <div class="iuacsoClass" v-html="concats">
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { homeprotocolPolicy } from "@/api/LoginPopUp.js";

export default {
    props: {
        concat: {
            type: String,
            default: ""
        },
        value: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    watch: {
        value: {
            handler(val) {
                this.show = val
                if (val) homeprotocolPolicy().then(res => {
                    const data = res?.data || {}
                    if (this.concat == '用户协议') this.concats = data?.userAgreement
                    else if (this.concat == '隐私政策') this.concats = data?.privacyPolicy
                })
            }
        }
    },
    data() {
        return {
            concats: "",
            show: false
        }
    }
}
</script>
<style lang="scss" scoped>
.iuacsoClass {
    max-height: 500px;
    word-break: break-all;
    overflow: auto;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}
</style>