import http from "@/utils/request";

// 个人信息
export const getCompanyInfo = (data) => {
  return http.request({
    url: '/api/company/getCompanyInfo',
    method: 'post',
    data
  })
}

// 我的订单状态
export const orderStatusList = (data) => {
  return http.request({
    url: '/api/company/orderStatusList',
    method: 'post',
    data
  })
}

// 我的订单
export const companyOrderList = (data) => {
  return http.request({
    url: '/api/company/companyOrderList',
    method: 'post',
    data
  })
}

// 查看项目详情
export const getProjectInfo = (data) => {
  return http.request({
    url: '/api/company/getProjectInfo',
    method: 'post',
    data
  })
}

// 项目管理修改
export const updateProjectInfo = (data) => {
  return http.request({
    url: '/api/company/updateProjectInfo',
    method: 'post',
    data
  })
}

// 个人信息
export const queryCompanyInfo = data => {
  return http.request({
    url: '/api/home/queryCompanyInfo',
    method: 'post',
    data
  })
}

// 修改企业信息
export const updateEnterpriseInfo = data => {
  return http.request({
    url: '/api/company/updateEnterpriseInfo',
    method: 'post',
    data
  })
}

// 查询企业信息
export const loginselectCompanyInfo = data => {
  return http.request({
    url: '/api/login/selectCompanyInfo',
    method: 'post',
    data
  })
}

// 修改企业信息
export const companyfirstUpdateEnterpriseInfo = data => {
  return http.request({
    url: '/api/company/firstUpdateEnterpriseInfo',
    method: 'post',
    data
  })
}

// 订单发票详情
export const orderDetail = data => {
  return http.request({
    url: '/api/company/orderDetail',
    method: 'post',
    data
  })
}

// 申请开票
export const openInvoice = data => {
  return http.request({
    url: '/api/company/openInvoice',
    method: 'post',
    data
  })
}

// 开发票详情
export const invoiceInfo = data => {
  return http.request({
    url: '/api/company/invoiceInfo',
    method: 'post',
    data
  })
}

// 取消开票
export const cancelInvoice = data => {
  return http.request({
    url: '/api/company/cancelInvoice',
    method: 'post',
    data
  })
}

// 获取项目列表
export const projectList = data => {
  return http.request({
    url: '/api/company/projectList',
    method: 'post',
    data
  })
}

// 项目详情
export const projectInfo = data => {
  return http.request({
    url: '/api/company/projectInfo',
    method: 'post',
    data
  })
}

// 我的订单详情
export const orderInfo = data => {
  return http.request({
    url: '/api/company/orderInfo',
    method: 'post',
    data
  })
}