

// 查询空对象
function isEmpty(object) {
	for (var name in object) {
		return false;
	}
	return true;
}

// 删除空参
export function deleteParameter(object) {
	for (var i in object) {
		var value = object[i];
		if (typeof value === 'object') {
			if (Array.isArray(value)) {
				if (value.length == 0) {
					delete object[i];
					continue;
				}
			}
			deleteParameter(value);
			if (isEmpty(value)) {
				delete object[i];
			}
		} else {
			if (value === '' || value === null || value === undefined) {
				delete object[i];
			}
		}
	}
}

// 排序
export function objectOrder(obj = {}) {
	var newkey = Object.keys(obj).sort();
	var newObj = {};
	for (var i = 0; i < newkey.length; i++) {
		newObj[newkey[i]] = JSON.stringify(obj[newkey[i]]);
	}
	return JSON.stringify(newObj).replace(/"\\"|\\""|'\\'|\\''/g, '"').replace(/\\n/g, 'n').replace(/\\r/g, 'r')
		.replace(/\\t/g, 't');
}

// 获取当前时间字符串
export function getFormatDate() {
	var date = new Date();
	var strYear = date.getFullYear().toString();
	var strMonth = date.getMonth() + 1;
	var strDate = date.getDate();
	var strHour = date.getHours();
	var strMinute = date.getMinutes();
	var strSecond = date.getSeconds();
	if (strMonth >= 1 && strMonth <= 9) {
		strMonth = "0" + strMonth;
	} else {
		strMonth = strMonth.toString();
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = "0" + strDate;
	} else {
		strDate = strDate.toString();
	}
	if (strHour >= 0 && strHour <= 9) {
		strHour = "0" + strHour;
	} else {
		strHour = strHour.toString();
	}
	if (strMinute >= 0 && strMinute <= 9) {
		strMinute = "0" + strMinute;
	} else {
		strMinute = strMinute.toString();
	}
	if (strSecond >= 0 && strSecond <= 9) {
		strSecond = "0" + strSecond;
	} else {
		strSecond = strSecond.toString();
	}
	return strYear + strMonth + strDate + strHour + strMinute + strSecond;
}

/**
* 参数处理--按顺序输出
* @param {*} params  参数
*/
export function tansSortParams(params) {
	let bodyParams = {};
	if (params) {
		for (const propName of Object.keys(params)) {
			const value = params[propName];
			if (value !== null && value !== "" && typeof (value) !== "undefined") {
				if (typeof value === 'object') {
					for (const key of Object.keys(value)) {
						if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
							let params = propName + '[' + key + ']';
							if (value[key] === 'object') {
								bodyParams[params] = JSON.stringify(value[key]);
							} else {
								bodyParams[params] = value[key].toString();
							}
						}
					}
				} else {
					bodyParams[propName] = value.toString();
				}
			}
		}
	}
	return objKeySort(bodyParams);
}

export function objKeySort(obj) { // 排序的函数
	var newkey = Object.keys(obj).sort()
	// 先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
	var newObj = {}// 创建一个新的对象，用于存放排好序的键值对
	for (var i = 0; i < newkey.length; i++) { // 遍历newkey数组
		newObj[newkey[i]] = obj[newkey[i]]// 向新创建的对象中按照排好的顺序依次增加键值对
	}
	return newObj// 返回排好序的新对象
}

/**
* 参数处理
* @param {*} params  参数
*/
export function tansParams(params) {
	let result = ''
	for (const propName of Object.keys(params)) {
		const value = params[propName];
		var part = encodeURIComponent(propName) + "=";
		if (value !== null && value !== "" && typeof (value) !== "undefined") {
			if (typeof value === 'object') {
				for (const key of Object.keys(value)) {
					if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
						let params = propName + '[' + key + ']';
						var subPart = encodeURIComponent(params) + "=";
						result += subPart + encodeURIComponent(value[key]) + "&";
					}
				}
			} else {
				result += part + encodeURIComponent(value) + "&";
			}
		}
	}
	return result
}
