<template>
    <div>
        <div class="headPageClass" @click="userFlg = false">
            <template v-if="['Index', 'CreditServices'].includes(this.$route.name) || true">
                <div class="ksizxuClass">
                    <img class="jsiaImgClass" src="@/static/headPage/bjtu.png" alt="">
                    <div class="uviuasiClass">
                        <img class="jviasiImgClass" src="@/static/headPage/logo.png" alt="">
                        <img class="isizoImgClass" src="@/static/headPage/biaoti.png" alt="">
                        <div class="uviasoicClass">
                            <img class="img1" src="@/static/headPage/sousuo.png" alt="">
                            <el-input class="inputClass" v-model="input" placeholder="请输入关键词搜索" />
                            <div class="vuasiClass">
                                搜索
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div ref="vusiaskjRef" class="zxckaisd">
                <div class="vusiaskjClass" ref="vusiaskjRef_1">
                    <div class="syuaicuClass">
                        <ol class="olClass">
                            <li class="liClass"
                                :class="{ liClassClick: ['personalCenter', 'Index', 'NotificationAnnouncement'].includes($route.name) }"
                                @click="navigationClick('/')">
                                网站首页
                            </li>
                            <li class="liClass" :class="{ liClassClick: ['AssociationIntroduction'].includes($route.name) }"
                                @click="navigationClick('/associationIntroduction')">
                                协会介绍
                            </li>
                            <li class="liClass"
                                :class="{ liClassClick: ['CreditServices', 'guaranteeList', 'GuaranteeVerification', 'guaranteeDetails', 'fillInfo'].includes($route.name) }"
                                @click="navigationClick('/creditServices')">
                                信用服务
                            </li>
                            <li class="liClass" :class="{ liClassClick: ['IntegrityCreation'].includes($route.name) }"
                                @click="navigationClick('/integrityCreation')">
                                诚信创建
                            </li>
                            <li class="liClass" :class="{ liClassClick: ['DynamicSupervision'].includes($route.name) }"
                                @click="navigationClick('/dynamicSupervision')">
                                动态监管
                            </li>
                            <li class="liClass" :class="{ liClassClick: ['TrainingCertification'].includes($route.name) }"
                                @click="navigationClick('/trainingCertification')">
                                培训认定
                            </li>
                            <li class="liClass" :class="{ liClassClick: ['CoEducationalBase'].includes($route.name) }"
                                @click="navigationClick('/coEducationalBase')">
                                共育基地
                            </li>
                            <li class="liClass" :class="{ liClassClick: ['ContactUs'].includes($route.name) }"
                                @click="navigationClick('/contactUs')">
                                联系我们
                            </li>
                        </ol>
                        <div class="vyasuClass">
                            <template v-if="userInfo.user == '1'">
                                <div class="oihzxcClass" @click.stop>
                                    <el-popover placement="bottom-start" trigger="hover" :teleported="false"
                                        :hide-after="100" :visible-arrow="false">
                                        <template #reference>
                                            <div class="lzxcoaClass" @click.stop="userFlg = !userFlg">
                                                <el-tooltip class="box-item" effect="dark" :content="userInfo.companyName"
                                                    placement="bottom">
                                                    <span class="span">
                                                        {{ userInfo.companyName }}
                                                    </span>
                                                </el-tooltip>

                                                <img class="img" :src="userInfo.companyImage" alt="">
                                            </div>
                                        </template>
                                        <div class="oqnlcClass">
                                            <img class="img1" :src="userInfo.companyImage" alt="">
                                            <el-tooltip class="box-item" effect="dark" :content="userInfo.companyName"
                                                placement="bottom">
                                                <div class="knzxcClass">
                                                    {{ userInfo.companyName }}
                                                </div>
                                            </el-tooltip>
                                            <div class="qwlkfnaClass">
                                                欢迎来到沈阳市信用协会
                                            </div>
                                            <el-button class="nmasclkClass" @click="gerenClick">进入个人中心</el-button>
                                            <el-button class="iuzxckClass" @click="tuioutClick">退出登录</el-button>
                                        </div>
                                    </el-popover>
                                </div>
                            </template>
                            <template v-else>
                                <img @click="() => {
                                    $store.dispatch('centerDialogVisibleFunction', true)
                                    if ($route.name == 'CreditServices') {
                                        $store.dispatch('pathUrlFunction', '/creditServices')
                                        $store.dispatch('userInfoFunction', { pathTo: '/creditServices' })
                                    }
                                    else $store.dispatch('userInfoFunction', { pathTo: '' })
                                }" class="jsizxyImgClass" src="@/static/headPage/denglu.png" alt="">
                                <img @click="zhuceClick" class="jvuasiImgClass" src="@/static/headPage/zhuce.png" alt="">
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LoginPopUp />
    </div>
</template>
<script>
import LoginPopUp from "@/components/LoginPopUp/index.vue";

export default {
    components: {
        LoginPopUp
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo
        },
    },
    mounted() {
        window.onscroll = () => {
            const valRef_1 = this.$refs.vusiaskjRef_1
            if (this.$refs.vusiaskjRef.getBoundingClientRect().top > 0) valRef_1.classList.remove('vusiaskjClass_1')
            else valRef_1.classList.add('vusiaskjClass_1')
        }
    },
    created() {
        window.onresize = () => {
            this.elaffixKey = Math.random()
        }
    },
    data() {
        return {
            autoClose: "",
            elaffixKey: "",
            userFlg: false,
            input: ""
        }
    },
    methods: {
        tuioutClick() {
            this.$store.dispatch('userInfoFunction', { user: '0' })
            if (['personalCenter'].includes(this.$route.name)) this.$router.push('/')
            else this.$router.go(0)
        },
        gerenClick() {
            this.userFlg = false
            this.navigationClick('/personalCenter')
        },
        navigationClick(url) {
            this.$router.push(url)
        },
        zhuceClick() {
            let vafls = false
            if (this.$route.name == 'CreditServices') {
                this.$store.dispatch('userInfoFunction', { pathTo: "/creditServices" })
                this.$store.dispatch('pathUrlFunction', '/creditServices')
            }
            else this.$store.dispatch('userInfoFunction', { pathTo: "" })
            if (this.$route.name == 'RegistrationPage') vafls = true
            this.$router.push('/registrationPage?name=Z').then(() => {
                if (vafls)
                    this.$router.go(0)
            })
        }
    }
}
</script>
<style>
.el-popover {
    background: transparent !important;
    border: none !important;
}
</style>
<style lang="scss" scoped>
@mixin ellipsis($cum: 3) {
    display: -webkit-box;
    -webkit-line-clamp: $cum;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.headPageClass {
    $width: 1200px;
    font-family: '思源黑体-Regular' !important;

    .ksizxuClass {
        position: relative;
        padding: 28px 0;
        background-color: white;

        .jsiaImgClass {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .uviuasiClass {
            position: relative;
            margin: 0 auto;
            width: $width;
            display: flex;
            justify-content: space-between;
            align-items: center;


            .jviasiImgClass {
                width: 224px;
                height: 54px;
            }

            .isizoImgClass {
                width: 215px;
                height: 30px;
            }

            .uviasoicClass {
                visibility: hidden;
                display: flex;
                align-items: center;
                background-color: #FFFFFF;
                border-radius: 30px;
                overflow: hidden;
                padding-left: 11px;
                box-sizing: border-box;
                width: 233px;
                height: 32px;


                .img1 {
                    width: 20px;
                    height: 20px;
                }

                .inputClass {
                    flex: 1;
                    margin-left: 10px;

                    :deep() {
                        .el-input__wrapper {
                            padding: 0;
                            background-color: transparent;
                            border-radius: 0;
                            box-shadow: none;

                            .el-input__inner::placeholder {
                                color: #999999;
                                font-size: 14px;
                                opacity: 1 !important;
                            }
                        }
                    }
                }

                .vuasiClass {
                    cursor: pointer;
                    margin-left: 10px;
                    width: 64px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #B92B30;
                    font-size: 14px;
                    color: #FFFFFF;
                }
            }
        }
    }

    $height: 56px;

    .zxckaisd {
        height: $height;
    }

    .vusiaskjClass {
        background-color: #B92B30;
        position: relative;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;

        .syuaicuClass {
            width: $width;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;

            :deep() {
                .el-popper {
                    padding: 0;
                    border: none;
                    box-shadow: none;
                }

                .el-popper__arrow:before {
                    background-color: transparent;
                    border: none;
                }
            }

            .olClass {
                display: flex;
                list-style: none;
                padding: 0;
                margin: 0;

                .liClass {
                    width: 117px;
                    height: $height;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    color: #FFFFFF;
                    cursor: pointer;
                    position: relative;


                }

                .liClass:hover {
                    background: #890010;
                    color: #EFB736;

                    // .oiasclClass {
                    //     display: block;
                    // }
                }

                .liClassClick {
                    background: #890010;
                    color: #EFB736;
                }

                .oiasclClass {
                    position: absolute;
                    top: -20px;
                    left: 0;
                    width: 120px;
                    height: 90px;
                    background: #FFFFFF;
                    border-radius: 5px 5px 5px 5px;
                    box-shadow: 0 0 10px #e4e4e4;

                    .xzcoClass {
                        position: absolute;
                        top: -5px;
                        left: 50%;
                        transform: translateX(-50%) rotate(45deg);
                        background-color: #FFFFFF;
                        width: 10px;
                        height: 10px;
                    }

                    .lzxcoiClass {
                        font-family: Source Han Sans CN, Source Han Sans CN;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;
                        text-align: center;


                        .oiacslClass {
                            margin-top: 12px;
                            cursor: pointer;
                        }

                        .oiacslClass:hover {
                            opacity: .6;
                        }

                        .mnasclClass {
                            margin: 0 auto;
                            margin-top: 12px;
                            width: 111px;
                            height: 0px;
                            border: 1px solid #E6E6E6;
                        }

                        .iacyabClass {
                            margin-top: 12px;
                            cursor: pointer;
                        }

                        .iacyabClass:hover {
                            opacity: .6;
                        }

                    }

                }
            }

            .vyasuClass {
                display: flex;
                align-items: center;

                .oihzxcClass {
                    position: relative;

                    .lzxcoaClass {
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        .span {
                            @include ellipsis(1);
                            max-width: 200px;
                            font-family: Source Han Sans CN, Source Han Sans CN;
                            font-weight: 400;
                            font-size: 14px;
                            color: #FFFFFF;
                        }

                        .img {
                            margin-left: 9px;
                            width: 38px;
                            height: 38px;
                            border-radius: 50%;
                            overflow: hidden;
                        }
                    }


                }

                @mixin kascu {
                    width: 72px;
                    height: 33px;
                    cursor: pointer;
                }

                .jsizxyImgClass {
                    @include kascu();
                }

                .jvuasiImgClass {
                    @include kascu();
                    margin-left: 8px;
                }

                .jsizxyImgClass:active,
                .jvuasiImgClass:active {
                    opacity: .6;
                }

            }
        }
    }

    .vusiaskjClass_1 {
        position: fixed;
    }
}

.oqnlcClass {
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0;
    width: 222px;
    height: 249px;
    background: #FFFFFF;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0 0 5px #d4d4d4;

    .img1 {
        margin: 0 auto;
        margin-top: 22px;
        width: 32.93px;
        height: 32.93px;
        border-radius: 50%;
        overflow: hidden;
    }

    .knzxcClass {
        @include ellipsis(1);
        text-align: center;
        margin-top: 9px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
    }

    .qwlkfnaClass {
        text-align: center;
        margin-top: 7px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
    }

    .nmasclkClass {
        display: block;
        margin: 0 auto;
        margin-top: 24px;
        width: 167px;
        background: #B92B30;
        border-radius: 5px 5px 5px 5px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: bold;
        font-size: 14px;
        color: #EFB736;
    }

    .iuzxckClass {
        display: block;
        margin: 0 auto;
        margin-top: 15px;
        width: 167px;
        border-radius: 5px 5px 5px 5px;
        border: 1px solid #E6E6E6;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
    }
}
</style>
