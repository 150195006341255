import http from "@/utils/request";

// 注册
export const loginregister = data => {
    return http.request({
        url: '/api/login/register',
        method: 'post',
        data
    })
}

// 发送手机验证码
export const loginsendCode = data => {
    return http.request({
        url: '/api/login/sendCode',
        method: 'post',
        data
    })
}

// 重置密码
export const loginresetPassword = data => {
    return http.request({
        url: '/api/login/resetPassword',
        method: 'post',
        data
    })
}

// 发送手机验证码 - 注册
export const loginregisterSendCode = data => {
    return http.request({
        url: '/api/login/registerSendCode',
        method: 'post',
        data
    })
}
