<template>
  <div>
    <template v-if="!$route.meta.external">
      <HeadPage />
    </template>
    <transition>
      <keep-alive include="Index">
        <router-view />
      </keep-alive>
    </transition>
    <template v-if="!$route.meta.external">
      <BottomPage />
    </template>
  </div>
</template>
<script>
import HeadPage from "@/components/HeadPage/index.vue";
import BottomPage from "@/components/BottomPage/index.vue";
export default {
  components: {
    HeadPage,
    BottomPage
  },
  data() {
    return {}
  }
}
</script>
<style lang="scss">
img {
  display: block;
}

div {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

// * {
//   -moz-user-select: none;
//   -webkit-user-select: none;
//   -ms-user-select: none;
//   -khtml-user-select: none;
//   user-select: none;
// }

body {

  // scrollbar-arrow-color: #ffffff;
  /*三角箭头的颜色*/
  scrollbar-face-color: #B92B30;
  /* 立体滚动条亮边的颜色 */
  scrollbar-highlight-color: #ddd;
  /* 滚动条的高亮颜色（左阴影？） */
  scrollbar-shadow-color: #ffffff;
  /* 立体滚动条阴影的颜色 */
  scrollbar-darkshadow-color: #ffffff;
  /* 立体滚动条外阴影的颜色 */
  scrollbar-track-color: #ffffff;
  /* 立体滚动条背景颜色 */
  scrollbar-base-color: #ffffff;
  /* 滚动条的基色 */
}

::-webkit-scrollbar {
  width: 12px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #B92B30;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  box-shadow: none;
}

/* 下面我们会解释这些 class 是做什么的 */
.v-enter-active,
.v-leave-active {
  transition: opacity 0s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}</style>